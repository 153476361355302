import React from 'react'
import styled from 'styled-components'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'

// Types
import { FormValues, LoginFormProps, OtherProps } from './interface'

const FormWrapper = styled('div')`
    ${({ theme }) => `
        width:100%;
        display:flex;
        align-items:left;
        flex-direction:column;
        form{
            width:90%;
            margin-left: 5%;
        }
    `}
`

const FormControl = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        flex-direction:column;
        margin-top:${theme?.spacing?.spc25};
        input{
            width: 100%;
            height:2rem;
            font-size:${theme?.fontSizes?.font18};
            background:${theme?.colors?.grey};
            border:none;
            border-radius:${theme?.spacing?.spc10};
        }
    `}
`

const ErrorDiv = styled('div')`
    ${({ theme }) => `
        color:${theme?.colors?.red};
        font-size:${theme?.fontSizes?.font16};
        font-family:Arial, Helvetica, sans-serif;
        margin-top:${theme?.spacing?.spc6};
    `}
`

const LoginLabel = styled('label')`
    ${({ theme }) => `
        font-size:${theme?.fontSizes?.font18};
        font-family:Arial, Helvetica, sans-serif;
        color:${theme?.colors?.darkGreen};
        margin-bottom:${theme?.spacing?.spc10};
    `}
`

interface ButtonLoginProps {
    isLoading?: boolean
}

const ButtonLogin = styled('button')<Pick<ButtonLoginProps, 'isLoading'>>`
    ${({ theme, isLoading }) => `
        display: flex;
        align-items: center;
        justify-content: center;
        background:${theme?.colors?.middleGreen};
        border:none;
        padding: ${theme?.spacing?.spc8} ${theme?.spacing?.spc30};
        border-radius:${theme?.spacing?.spc10};
        color:${theme?.colors?.white};
        margin-top:${theme?.spacing?.spc30};
        font-size:${theme?.fontSizes?.font22};
        font-family:Arial, Helvetica, sans-serif;
        cursor: pointer;
        ${isLoading ? `background: rgb(0, 133, 116, 0.5);` : ''}
        @media screen and (max-width:768px){
            width:100%;
        }
`}
`

const validation = Yup.object({
    email: Yup.string()
        .max(45, 'Correo electrónico demasiado largo')
        .email('Ingresa un correoelectrónico válido')
        .required('Ingresa un correoelectrónico válido'),
    passwd: Yup.string()
        .max(45, 'Contraseña demasiado larga')
        .required('Ingresa una contraseña válida'),
})

const LoginForm = ({
    handleSubmit,
    initialValues,
    isLoading,
}: LoginFormProps) => {
    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, actions) => handleSubmit(values, actions)}
                validationSchema={validation}
            >
                {(props: OtherProps & FormikProps<FormValues>) => {
                    const { touched, errors } = props
                    return (
                        <FormWrapper>
                            <Form>
                                <FormControl>
                                    <LoginLabel htmlFor="email">
                                        Correo:
                                    </LoginLabel>
                                    <Field id="email" name="email" />
                                    {touched.email && errors.email && (
                                        <ErrorDiv>{errors.email}</ErrorDiv>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <LoginLabel htmlFor="password">
                                        Contraseña:
                                    </LoginLabel>
                                    <Field
                                        id="passwd"
                                        name="passwd"
                                        type="password"
                                    />
                                    {touched.passwd && errors.passwd && (
                                        <ErrorDiv>{errors.passwd}</ErrorDiv>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <ButtonLogin
                                        isLoading={isLoading}
                                        type="submit"
                                    >
                                        Iniciar sesión
                                    </ButtonLogin>
                                </FormControl>
                            </Form>
                        </FormWrapper>
                    )
                }}
            </Formik>
        </>
    )
}

export default LoginForm
